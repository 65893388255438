import React, { Component, Fragment } from 'react';
import { Helmet } from "react-helmet"
// import { withPrefix } from "gatsby"
import { navigate } from 'gatsby';
import { Provider as ReduxProvider } from "react-redux";
import store from "../redux/store";
import Header from './Header'
// import Navigation from './Navigation';
import getFirebase, { FirebaseContext } from './Firebase';
import withAuthentication from './Session/withAuthentication';
import * as ROUTES from '../constants/routes'

class Layout extends Component {
  state = {
    firebase: null,
  };

  componentDidMount() {
    const app = import('firebase/app');
    const auth = import('firebase/auth');
    const database = import('firebase/database');
    const firestore = import('firebase/firestore');
    const functions = import('firebase/functions');

    Promise.all([app, auth, database, firestore, functions]).then(values => {
      const firebase = getFirebase(values[0]);

      this.setState({ firebase });
    });
  }

  render() {
    return (
      <FirebaseContext.Provider value={this.state.firebase}>
        <ReduxProvider store={store}>
          <AppWithAuthentication {...this.props} />
        </ReduxProvider>
      </FirebaseContext.Provider>
    );
  }
}

class NoHeadFootLayout extends Layout {
  render() {
    return (
      <FirebaseContext.Provider value={this.state.firebase}>
        <ReduxProvider store={store}>
          <AutoRedirectToHomeIfAuth {...this.props} />
        </ReduxProvider>
      </FirebaseContext.Provider>
    );
  }
}

const DefaultHelmet = () => (
  <Helmet>
    <title>VIP Reports</title>
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <link href="https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,700,700i,900,900i|Source+Sans+Pro:300,300i,400,400i,600,600i,700,700i,900,900i&display=swap" rel="stylesheet" />
    {/* <link rel="stylesheet" type="text/css" href="/fonts/css/fontawesome-all.min.css" /> */}
    {/* <script src={withPrefix('template/js/custom.js')} type="text/javascript" /> */}
  </Helmet>
);

const AppWithAuthentication = withAuthentication(({ children }) => (
  <Fragment>
    <DefaultHelmet />
    <div id="page">
      <Header />
      <div style={{ marginBottom: '2%' }}>
        {children}
      </div>
      {/* <Navigation /> */}
    </div>
  </Fragment>
));

class AutoRedirectToHomeIfAuth extends Component {
  authUser = null;
  componentDidMount() {
    this.authUser = JSON.parse(localStorage.getItem('authUser'));
    if (this.authUser)
      navigate(ROUTES.HOME)
  }

  render() {
    return (
      <Fragment>
        <DefaultHelmet />
        {this.authUser ? null : (<div id="page">{this.props.children}</div>)}
      </Fragment>
    );
  }

};

export default Layout;

export { DefaultHelmet, NoHeadFootLayout };
