import { combineReducers } from "redux";
import { SET_PLACE, SET_ACTIVE_DEVICE } from "../actionTypes";

const initialState = {};

const place = (state = initialState, action) => {
    switch (action.type) {
        case SET_PLACE: {
            const { place } = action.payload
            return { place }
        }

        default:
            return state
    }
}

const activeDevice = (state = initialState, action) => {
    switch (action.type) {
        case SET_ACTIVE_DEVICE: {
            const { device } = action.payload
            return { device }
        }
        default:
            return state
    }
}

export default combineReducers({ place, activeDevice });
