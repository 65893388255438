import { createStore } from "redux";
import rootReducers from "./reducers";
import { stringify, parse } from 'json-cycle'


function replacer(key, value) {
    // Filtering out properties
    if (typeof value === 'string') {
        return undefined;
    }
    return value;
}


function saveToLocalStorage(state,) {
    try {
        let serialisedState = stringify(state,);
        if (typeof window !== `undefined`)
            localStorage.setItem("PLACE_STATE", serialisedState);
    } catch (e) {
        console.warn(e);
    }
}

function loadFromLocalStorage() {
    try {
        let serialisedState = null
        if (typeof window !== `undefined`)
            serialisedState = localStorage.getItem("PLACE_STATE");
        if (serialisedState === null) return undefined;
        return parse(serialisedState);
    } catch (e) {
        console.warn(e);
        return undefined;
    }
}


const store = createStore(rootReducers, loadFromLocalStorage());

store.subscribe(() => saveToLocalStorage(store.getState()));

export default store;