// const config = {
//   apiKey: process.env.GATSBY_API_KEY,
//   authDomain: process.env.GATSBY_AUTH_DOMAIN,
//   databaseURL: process.env.GATSBY_DATABASE_URL,
//   projectId: process.env.GATSBY_PROJECT_ID,
//   storageBucket: process.env.GATSBY_STORAGE_BUCKET,
//   messagingSenderId: process.env.GATSBY_MESSAGING_SENDER_ID,
//   appId: process.env.GATSBY_APP_ID,
//   measurementId: process.env.GATSBY_MEASUREMENT_ID
// };

const config = {
    apiKey: "AIzaSyCUhmC_Z2XA_9sACFeeTeJA5jOWlAq4OGM",
    authDomain: "vip-iot-plus.firebaseapp.com",
    databaseURL: "https://vip-iot-plus.firebaseio.com",
    projectId: "vip-iot-plus",
    storageBucket: "vip-iot-plus.appspot.com",
    messagingSenderId: "698904180103",
    appId: "1:698904180103:web:9bb04330fa9df0db93cf22",
    measurementId: "G-WTP614GY3B"
};

class Firebase {
    constructor(app) {
        if (!app.apps.length) {
            app.initializeApp(config);
        }

        // console.log(process.env.GATSBY_API_KEY);
        /* Helper */

        this.serverValue = app.database.ServerValue;
        this.emailAuthProvider = app.auth.EmailAuthProvider;

        /* Firebase APIs */

        this.auth = app.auth();
        this.database = app.database();
        this.firestore = app.firestore();
        this.functions = app.app().functions('asia-east2');

        this.firestore.enablePersistence()
        .catch((err) => {
            console.log(err.code)
            if (err.code == 'failed-precondition') {
                // Multiple tabs open, persistence can only be enabled
                // in one tab at a a time.
                // ...
            } else if (err.code == 'unimplemented') {
                // The current browser does not support all of the
                // features required to enable persistence
                // ...
            }
        });

        /* Social Sign In Method Provider */

        this.googleProvider = new app.auth.GoogleAuthProvider();
        this.facebookProvider = new app.auth.FacebookAuthProvider();
        this.twitterProvider = new app.auth.TwitterAuthProvider();
    }

    // *** Auth API ***

    doCreateUserWithEmailAndPassword = (email, password) =>
        this.auth.createUserWithEmailAndPassword(email, password);

    doSignInWithEmailAndPassword = (email, password) =>
        this.auth.signInWithEmailAndPassword(email, password);

    doSignInWithGoogle = () =>
        this.auth.signInWithPopup(this.googleProvider);

    doSignInWithFacebook = () =>
        this.auth.signInWithPopup(this.facebookProvider);

    doSignInWithTwitter = () =>
        this.auth.signInWithPopup(this.twitterProvider);

    doSignOut = () => this.auth.signOut();

    doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

    doSendEmailVerification = () =>
        this.auth.currentUser.sendEmailVerification({
            url: process.env.GATSBY_CONFIRMATION_EMAIL_REDIRECT,
        });

    doPasswordUpdate = password =>
        this.auth.currentUser.updatePassword(password);

    // *** Merge Auth and DB User API *** //

    onAuthUserListener = (next, fallback) =>
        this.auth.onAuthStateChanged(authUser => {
            if (authUser) {
                this.user(authUser.uid)
                    .get()
                    .then(snapshot => {
                        const dbUser = snapshot.data();
                        // console.log(dbUser)
                        // default empty roles
                        // if (!dbUser.roles) {
                        //   dbUser.roles = {};
                        // }

                        // merge auth and db user
                        authUser = {
                            uid: authUser.uid,
                            email: authUser.email,
                            emailVerified: authUser.emailVerified,
                            providerData: authUser.providerData,
                            isVip: /^.+@(vipagri|vipinterhealth)\.co\.th/.test(authUser.email),
                            ...dbUser,
                        };

                        next(authUser);
                    });
            } else {
                fallback();
            }
        });

    // *** User API ***
    user = uid => this.firestore.collection("users").doc(`${uid}`); //this.database.ref(`users/${uid}`);

    users = () => this.firestore.collection("users");

    // *** Place API ***
    place = placeId => this.firestore.collection("places").doc(`${placeId}`)

    places = (authUser) => {
            // console.log(`is VIP: ${authUser.isVip }`)
            return authUser.isVip ? 
                this.firestore.collection("places").orderBy(`name`):
                this.firestore.collection("places").where("user_emails", "array-contains", authUser.email).orderBy(`name`);
    }
   

    // *** Device API ***
    device = (deviceId) => this.firestore.collection(`devices`).doc(`${deviceId}`);

    devices = () => this.firestore.collection('devices');

    devicesByIdList = (ids) => this.firestore.collection('devices')
                        .where('__name__', 'in', ids);

    devicesByPlaceId = (placeId) => this.firestore.collection('devices')
                        .where('places', 'array-contains', placeId);

    getDataListByDeviceId = async (deviceId,) => {
        return await this.firestore.collection(`devices`)
            .doc(`${deviceId}`)
            .collection(`data-list`)
            .get()
    }

    getSensorsList = async (deviceId) => {
        return await this.firestore.collection(`devices`)
            .doc(`${deviceId}`)
            .collection(`sensors-list`)
            .get()
    }

    getSensorsListById= async (deviceId, id) => {
        return await this.firestore.collection(`devices`)
            .doc(`${deviceId}`)
            .collection(`sensors-list`)
            .doc(`${id}`)
            .get()
    }

    getSensorTypeBySensorId = async (sensorId) => {
        return await this.firestore.collection(`sensor-type`)
            .doc(sensorId)
            .get()
    }

    getTelemetryType = async (telemetryTypeId) => {
        return await this.firestore.collection(`telemetry-type`)
            .doc(`${telemetryTypeId}`)
            .get()
    }


    // cloud functions
    // getTelemetryList = devId => this.functions.httpsCallable('getTelemetryList')({devId: devId})

    //Readtime Database
    realtimeTelemetryList = devId => this.database.ref(`devices/${devId}`)

    realtimeData = (devId, dataTypeId, dataIdx) => this.database.ref(`devices/${devId}/${dataTypeId}/${dataIdx}`)

    deviceStates = () => this.database.ref(`deviceStates`)
}

let firebase;

function getFirebase(app, auth, database, functions) {
    if (!firebase) {
        firebase = new Firebase(app, auth, database, functions);
    }

    return firebase;
}

export default getFirebase;
