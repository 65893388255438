
import React, { Component } from 'react';
import { Header as Head, Menu, Segment } from 'semantic-ui-react'
import { withFirebase } from '../Firebase';
import { navigate } from "gatsby"
import { HOME } from "../../constants/routes"

const style = {
  h1: {
    marginTop: '3em',
  },
  h2: {
    margin: '4em 0em 2em',
  },
  h3: {
    marginTop: '2em',
    padding: '2em 0em',
  },
  last: {
    marginBottom: '300px',
  },
}

class Header extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <>
        <div>
          <Menu style={{ backgroundColor: "#EEEEEE" }} >
            <Menu.Item>
              <img src='/icons/icon-96x96.png' />
            </Menu.Item>
            <Menu.Item onClick={() => { navigate(HOME) }}>Home</Menu.Item>
            {/* <Menu.Item>Add Place</Menu.Item> */}
            <Menu.Item onClick={this.props.firebase ? this.props.firebase.doSignOut : () => { /*console.log(this.props.firebase)*/ }}>Sign-out</Menu.Item>
            <Menu.Menu position='right'>
              <Menu.Item>
                v1.2.5
              </Menu.Item>
            </Menu.Menu>
          </Menu>
          {/* </Container> */}
        </div>
        <br />
      </>
    );
  }
}


export default withFirebase(Header);
